import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { StyledToast } from '../../../UI'
import { ToastThemeVariant, ToastThemeVariantValues } from '../../../UI/Toast/Toast.type'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import styles from './styles/TermsAndConditions.module.scss'
import HTMLReactParser from 'html-react-parser'
import { ToastVisibilityContext } from '../HeroBanner'

type TermsAndConditionsProps = {
  className?: string
  dataElementId?: string
  teaser: ILXTeaser | undefined
}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ className, dataElementId, teaser }) => {
  const terms = teaser?.targetsTermsAndConditions
  const { target, text, style } = terms ?? {}

  const [isOpen, setIsOpen] = useState(false)
  const toastContext = useContext(ToastVisibilityContext)
  
  useEffect(() => {
    if (!toastContext.isToastOpen && isOpen) {
      setIsOpen(false)
    }
  }, [toastContext.isToastOpen])

  const handleOpen = () => {
    const newIsOpen = !isOpen
    setIsOpen(newIsOpen)
    toastContext.setIsToastOpen(newIsOpen)
  }

  const handleClose = () => {
    setIsOpen(false);
    toastContext.setIsToastOpen(false)
  }

  const variantFromStyle = style?.split('-').pop() as ToastThemeVariant
  const toastVariant: ToastThemeVariant = ToastThemeVariantValues.hasOwnProperty(variantFromStyle)
    ? variantFromStyle
    : 'primary'
  const variant: ToastThemeVariant = ToastThemeVariantValues.hasOwnProperty(variantFromStyle)
    ? variantFromStyle
    : 'tertiary'

  const themeStyles = {
    primary: 'white',
    secondary: '#1c4da1',
    tertiary: 'black',
  }

  const buttonColor = themeStyles[variant]

  return !target ? null : (
    <div className={clsx('terms-and-conditions', styles.termsAndConditions, className)}>
      <button
        aria-label={text}
        data-element-id={`${dataElementId ?? 'TermsAndConditions'}_${isOpen ? 'SHOW' : 'HIDE'}`}
        className={styles.termsAndConditionsCta}
        onClick={handleOpen}
        style={{ color: buttonColor }}
      >
        <SVGIcon library="validation" name="info" color={buttonColor} />
        {HTMLReactParser(text ?? '')}
      </button>

      <StyledToast
        body={target.detailText}
        headingText={target.title}
        onClose={handleClose}
        open={isOpen}
        themeVariant={toastVariant}
      />
    </div>
  )
}

import { useContext, useState, createContext, useRef, useEffect } from 'react'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, type Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { TextModuleBanner } from '../TextModuleBanner'
import { ShoppableTeaser } from '../ShoppableTeaser'
import { hasOverlay, hasTermsAndConditions, isShoppableTeaser } from '@utils/cms/teaser'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { CmsMedia } from '../CmsMedia'
import styles from '../Media/MediaWrapper.module.scss'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import heroBannerStyles from './styles/HeroBanner.module.scss'
import { CRITICAL_INDEX } from '@components/Cms/constants'

export const ToastVisibilityContext = createContext({
  isToastOpen: false,
  setIsToastOpen: (isOpen: boolean) => {},
  closeToast: () => {}
})

type HeroBannerProps = {
  videoWrapperClassName?: string
}

export const HeroBanner: React.FC<HeroBannerProps> = props => {
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teasers = data?.placement?.items?.filter(isLXTeaser) || []

  const { videoWrapperClassName } = props
  
  const [isToastOpen, setIsToastOpen] = useState(false)
  const swiperRef = useRef<SwiperType | null>(null)
  
  const closeToast = () => {
    setIsToastOpen(false)
  }

  useEffect(() => {
    if (swiperRef.current?.pagination?.el) {
      const paginationEl = swiperRef.current.pagination.el
      
      if (isToastOpen) {
        paginationEl.style.display = 'none'
      } else {
        paginationEl.style.display = ''
      }
    }
  }, [isToastOpen])

  return (
    <ToastVisibilityContext.Provider value={{ isToastOpen, setIsToastOpen, closeToast }}>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        className={heroBannerStyles.heroBanner}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
          if (isToastOpen && swiper.pagination.el) {
            swiper.pagination.el.style.display = 'none'
          }
        }}
        onSlideChange={() => {
          closeToast()
        }}
      >
        {teasers.map((teaser, index) => {
          const {
            media,
            teaserLXCallToActionSettings,
            teaserBackground,
          } = teaser || {}

          const isShoppable = isShoppableTeaser(teaser)
          const hasTerms = hasTermsAndConditions(teaser)

          return (
            <SwiperSlide key={index}>
              <section
                className={clsx(
                  'cms-section',
                  styles['media-wrapper'],
                  heroBannerStyles.heroBanner,
                  teaserBackground,
                  {
                    [`has-cta-${teaserLXCallToActionSettings?.length}`]: true,
                    'no-cta': !teaserLXCallToActionSettings?.length,
                    'is-shoppable': isShoppable,
                    'has-terms': hasTerms,
                    'has-overlay': true,
                  }
                )}
              >
                {isShoppable && <ShoppableTeaser />}
                <CmsMedia
                  media={media}
                  hasOverlay={hasOverlay(teaser)}
                  lazy={data?.index >= CRITICAL_INDEX}
                  cropType="LANDSCAPE_BANNER_M"
                  videoClassName={videoWrapperClassName}
                />
                <TextModuleBanner/>
              </section>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </ToastVisibilityContext.Provider>
  )
}

